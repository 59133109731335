import React from 'react'
import styled from 'styled-components'

import Screens from 'theme/screens'
import { useText } from 'texts'

import Term from './Term'

const terms = [
  {
    tKey: 't1',
    titleKey: 'title',
    subtermsKey: 'subterms',
    subterms: [
      't1', 
      't2', 
      't3', 
      't4', 
      {
        tKey: 't5',
        titleKey: 'title',
        subtermsKey: 'subterms',
        subterms: ['t1', 't2']
      }, 
      't6', 
      't7', 
      't8', 
      't9', 
      't10', 
      't11', 
      't12',
      {
        tKey: 't13',
        titleKey: 'title',
        subtermsKey: 'subterms',
        subterms: ['t1', 't2']
      },
      {
        tKey: 't14',
        titleKey: 'title',
        subtermsKey: 'subterms',
        subterms: ['t1', 't2']
      },  
      't15'
    ]
  },
  {
    tKey: 't2',
    titleKey: 'title',
    subtermsKey: 'subterms',
    subterms: ['t1']
  },
  {
    tKey: 't3',
    titleKey: 'title',
    subtermsKey: 'subterms',
    subterms: ['t1', 't2', 't3', 't4', 't5', 't6']
  },
  {
    tKey: 't4',
    titleKey: 'title',
    subtermsKey: 'subterms',
    subterms: [
      't1',
      {
        tKey: 't2',
        titleKey: 'title',
        subtermsKey: 'subterms',
        subterms: ['t1', 't2', 't3', 't4']
      },
      {
        tKey: 't3',
        titleKey: 'title',
        subtermsKey: 'subterms',
        subterms: ['t1', 't2']
      },
      {
        tKey: 't4',
        titleKey: 'title',
        subtermsKey: 'subterms',
        subterms: ['t1']
      },
      't5', 't6', 't7', 't8',
      {
        tKey: 't9',
        titleKey: 'title',
        subtermsKey: 'subterms',
        subterms: ['t1', 't2']
      },
      {
        tKey: 't10',
        titleKey: 'title',
        subtermsKey: 'subterms',
        subterms: ['t1']
      },
       't11', 't12', 't13', 't14'
    ]
  },
  {
    tKey: 't5',
    titleKey: 'title',
    subtermsKey: 'subterms',
    subterms: [
      't1', 't2',       
      {
        tKey: 't3',
        titleKey: 'title',
        subtermsKey: 'subterms',
        subterms: ['t1', 't2']
      },
      't4', 't5', 't6'
    ]
  },
  {
    tKey: 't6',
    titleKey: 'title',
    subtermsKey: 'subterms',
    subterms: ['t1', 't2']
  },
]

const buildTerms = (terms, isSubterm, tKey, parentTermIndex) =>
  terms.map((term, index) => {
    let indexString = index + 1
    let termIndex = parentTermIndex ? `${parentTermIndex}.${indexString}` : indexString
    if (typeof term === 'string' || term instanceof String) return (
      <Term
        term={`${tKey}.${term}`}
        isSubterm={true}
        highlight={index % 2 === 0}
        termIndex={termIndex}
      />
    )

    if ('subterms' in term) {
      let children = buildTerms(term.subterms, true, `${tKey}.${term.tKey}.${term.subtermsKey}`, termIndex)
      return (
        <Term
          children={children}
          term={`${tKey}.${term.tKey}.${term.titleKey}`}
          isSubterm={isSubterm}
          highlight={index % 2 === 0}
          termIndex={termIndex}
        />
      )
    }

  })

const StyledTermsOfUse = styled.div`
  width: 100%;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 20%;
  padding-right: 20%;
  z-index: 1;

  @media (max-width: ${Screens.md}) {
    min-width: auto;
  }

  @media (max-width: ${Screens.sm}) {
    position: relative;
    max-width: 100%;
    padding-right: 20%;
    align-items: center;
    text-align: center;
  }
`

const TermsContainer = styled.div`
  width: 100%;
`
const TermsOfUse = () => {
  const { t } = useText()

  return (
    <StyledTermsOfUse>
      <TextContainer>
        <h2 className="text-primary-100">{t('termsOfUse.hero.title')}</h2>
      </TextContainer>
      <TextContainer>
        <h3 className="text-primary-100">{t('termsOfUse.disclaimer.title')}</h3>
        <div>{t('termsOfUse.disclaimer.description')}</div>
      </TextContainer>
      <TermsContainer>
        {buildTerms(terms, false, 'termsOfUse.terms', '')}
      </TermsContainer>
    </StyledTermsOfUse>
  )
}

export default TermsOfUse
