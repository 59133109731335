import React from 'react'

import Layout from 'components/Layout'
import TermsOfUse from 'components/TermsOfUse'
import Seo from 'components/Shared/SEO'
import { useText } from 'texts'

const TermsOfUsePage = ({ path }) => {
  const { t } = useText()

  return (
    <Layout>
      <Seo
        path={path}
        title={t('termsOfUse.seo.title')}
        description={t('termsOfUse.seo.description')}
      />
      <TermsOfUse />
    </Layout>
  )
}

export default TermsOfUsePage
